import PromoBYDShark from "../../../images/promo/PromoBYDShark.png";
import PromoBYDSharkMobile from "../../../images/promo/PromoBYDSharkMobile.png";
import PromoMitsubishOut from "../../../images/promo/PromoMitsubishiOutlander.png";
import PromoMitsubishOutMobile from "../../../images/promo/PromoMitsubishiOutlanderMobile.png";
import PromoXpeng from "../../../images/promo/PromoXpeng.png";
import PromoXpengMobile from "../../../images/promo/PromoXpengMobile.png";

const dataSlider = (hidePartnered) => [
  {
    isTextImage: false,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: true,
    hideBanner: hidePartnered, // true will remove this section from the slider
    titleBadge: "",
    vehicleTitle: "",
    vehicleSubTitle: "",
    priceDollar: "",
    priceCents: "",
    period: "",
    disclaimerText: "",
    image: "",
    imageMobile: "",
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Exclusive offer NLA only",
    vehicleTitle: "Mitsubishi Outlander range",
    vehicleSubTitle: "Additional Discounts of up to $4,000 on top of National Fleet Discounts.",
    priceDollar: "218",
    priceCents: "",
    period: "weekly*",
    disclaimerText:
      "* Discount levels ranging based on Outlander variant.",
    image: PromoMitsubishOut,
    imageMobile: PromoMitsubishOutMobile,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Exclusive offer NLA only",
    vehicleTitle: "XPENG G6",
    vehicleSubTitle: "Extended Warranty & $500 Accessories Credit. Exclusive to NLA Customers Only.",
    priceDollar: "199",
    priceCents: "",
    period: "weekly*",
    disclaimerText:
      "* Based a on a driver in NSW for a 5 year lease term, with a $100,000 salary, and 15,000km driven annually.",
    image: PromoXpeng,
    imageMobile: PromoXpengMobile,
  },
  {
    isTextImage: true,
    isTextImageNoPrice: false,
    isFullImage: false,
    isPartner: false,
    hideBanner: false, // true will remove this section from the slider
    titleBadge: "Popular New Model",
    vehicleTitle: "BYD Shark GL",
    vehicleSubTitle: "Be one of the first in Australia to drive the BYD Shark.",
    priceDollar: "298",
    priceCents: "",
    period: "weekly*",
    disclaimerText:
      "* Based a on a driver in NSW for a 5 year lease term, with a $100,000 salary, and 15,000km driven annually.",
    image: PromoBYDShark,
    imageMobile: PromoBYDSharkMobile,
  },
];

export default dataSlider;
